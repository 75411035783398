body {
	width: 100%;
	min-height: 100%;
	transition: opacity 0.4s;

	.wait-for-js {
		transition: opacity 0.4s;
	}

	.is-loading {
		.wait-for-js {
			opacity: 0 !important;
		}
	}

	.is-waiting {
		opacity: 0 !important;
	}

	#__next {
		width: 100%;
		height: 100%;
	}

	&.stop-scroll {
		overflow: hidden;
	}
}

.container {
	display: grid;
	grid-template-columns: repeat(6, [col-start] 1fr);
	gap: $mobile-gutter;
	width: 100%;
	padding-left: $mobile-gutter + 6px;
	padding-right: $mobile-gutter + 6px;
	max-width: $container-max + $gutter;
	margin-left: auto;
	margin-right: auto;

	& .section {
		background: $white;
		padding: 20px;

		&.section--bottomMargin {
			margin-bottom: 20px;
		}

		&--dark {
			color: $deep-green;
			background: $light-grey;
		}
	}

	&.container--noMaxWidth {
		max-width: none;
	}

	&.containerSlim {
		@include desktop() {
			grid-column: 2 / span 10;
		}
	}

	&.noGap {
		gap: 0px;
	}

	&.largeMd {
		gap: 20px;
	}

	&.narrow {
		gap: 10px;
	}

	&.medium {
		gap: 15px;
	}

	&.largeContainer {
		gap: 30px;
	}

	&Flex {
		display: flex;
		flex-direction: row;

		&.containerCenter {
			justify-content: center;
		}
	}

	&.containerNoPad {
		padding-left: 0;
		padding-right: 0;
	}

	& > * {
		grid-column: 1 / span 6;

		@include tablet {
			grid-column: 1 / span 12;
		}
	}

	& > p {
		display: block;
	}

	&.containerSplit {
		grid-column: span 3;

		@include tablet {
			grid-column: span 6;
		}
	}

	@include tablet {
		grid-template-columns: repeat(12, [col-start] 1fr);
		gap: $tablet-gutter;
		padding-left: $tablet-gutter;
		padding-right: $tablet-gutter;
	}

	@include desktop {
		grid-template-columns: repeat(12, [col-start] 1fr);
		column-gap: $desktop-gutter;
		row-gap: $gutter;
		padding-left: $desktop-gutter;
		padding-right: $desktop-gutter;
		max-width: (12 * $max-col-width) + (12 * $desktop-gutter);
	}

	&[class*="containerSix"] {
		& > * {
			grid-column: 1 / span 6;
		}

		@include tablet {
			grid-template-columns: repeat(6, [col-start] 1fr);
		}

		@include desktopLarge {
			grid-template-columns: repeat(6, [col-start] 1fr);
		}

		&.containerSplit {
			grid-column: span 3;

			@include tablet {
				grid-column: span 3;
			}
		}
	}
}

.dualColumn {
	&__left {
		@include tabletLarge() {
			grid-column: 2 / span 5;
			width: calc(100% + 23px);

			&.wider {
				grid-column: 1 / span 6;
			}
		}
	}

	&__divider {
		@include tabletLarge() {
			grid-column: 7 / span 1;
			position: relative;
			display: block;

			&::after {
				content: " ";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				width: 1px;
				background: #ccc;
			}
		}
	}

	&__right {
		@include tabletLarge() {
			grid-column: 8 / span 4;
			width: calc(100% + 23px);
			margin-left: -23px;

			&.wider {
				grid-column: 8 / span 5;
			}
		}
	}
}

.center-content {
	display: flex;
	justify-content: center;
}

.center-self {
	margin-left: auto;
	margin-right: auto;
}

.center-self-grid {
	grid-column: span 6;

	@include tablet {
		grid-column: 3 / span 8;
	}
}

p.margin-top {
	margin-top: 20px;
}

@for $i from 1 through 12 {
	.gridColStart#{$i} {
		grid-column-start: $i;
	}

	.gridColEnd#{$i} {
		grid-column-end: $i + 1;
	}
}

.hide-md {
	@include tabletLarge() {
		display: none;
	}
}

.show-md {
	@include tabletLarge() {
		display: block;
	}
}

.splitText {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 10px;
	margin-bottom: 10px;

	&.splitTextHeading,
	&.largeMargin {
		margin-bottom: 20px;
	}

	@include tablet() {
		row-gap: 20px;
		margin-bottom: 20px;

		&.splitTextHeading {
			margin-bottom: 30px;
		}
	}

	& > * {
		flex-basis: 50%;
		margin-bottom: 0;

		&:nth-child(odd) {
			text-align: left;
		}

		&:nth-child(even) {
			text-align: right;
		}
	}
}

// SECTIONS
.sectionSpacing {
	padding-top: 40px;
	padding-bottom: 40px;

	@include tabletLarge() {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	@include desktop() {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.sectionSpacingSmall {
	padding-top: 40px;
	padding-bottom: 40px;
}

.sectionSpacingSmallTablet {
	@include tabletLarge() {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}

.sectionSpacingMedium {
	padding-top: 50px;
	padding-bottom: 50px;
}

// Ideally refactor this, but spacing is becoming inconsistent so add these now to not interefere with existing stylings

.sectionSpacingMediumResponsive {
	padding-top: 40px;
	padding-bottom: 40px;

	@include tabletLarge() {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.sectionSpacingNoTop {
	padding-top: 0;
}

.br {
	clear: both;
	height: 20px;
}
